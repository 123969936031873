
.gallery-headline {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    background: white;

    h1 {
        font-size: 10rem; 
        margin-top: 100px;
        font-weight: $medium;
        letter-spacing: -0.03em;
        line-height: 0.8em;
        margin-bottom: 0.8em;
        text-align: center;

        @media (max-width: $mobile) {
            font-size: 7rem;
            display: none;
        }
    }

    img {
        width: 100%;
        display: block;
        margin: auto;
    }

}

@media only screen and (orientation: landscape) {
    #intro-wrapper {
        min-height: 100vh;
    }    
}

.gallery-mainImage-wrapper {
    width: 100%;
    background: white;

    .image-container {
        padding-top: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 40px;

      

        @media (max-width: $mobile) {
            padding-top: 100%;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 55px;
        }
    }
}

.gallery {

    height: 100vh;
    background: white;

    @media (max-width: $mobile) {
        height: auto !important;
    }

    .gallery__drag {
        width: 2px;
        height: 60px;
        background: #efebe7;
        position: fixed;
        top: 0;
        left: 50%;
        height: 100vh;
        z-index: 5;

        @media (max-width: $mobile) {
            display: none !important;
        }

        span {
            position: absolute;
            top:0;
            bottom:0;
            width: 15px;
            background: transparent;
            z-index: 10;
            transform: translateX(-50%);
        }
    
        &:hover {
            cursor: col-resize;
    
            &:after {
                background: #ccc;
            }
        }
    
        &:after {
            position: absolute;
            content: ' ';
            width: 2px;
            height: 200px;
            background: black;
            top: calc(50% + 25px);
            left: 0;
            transform: translateY(-50%);
        }
    }

    .gallery__inner {
        height: 100vh;

        @media (max-width: $mobile) {
            height: auto !important;
        }
    }

    .custom-scrollbar
    {
        height: 400px;
        width: 100%;
        background: #ffffff;
        overflow-y: scroll;
        margin-bottom: 25px;
        padding-right: 40px;
        direction: rtl;
        transform: translateX(-25px);
        padding-left: 20px;

        @media (max-width: $mobile) {
            height: auto !important;
        }

        .scroll-inner {
            direction: ltr;
        }
    }

        #scroll-style::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
            border-radius: 10px;
            background-color: #fff;
        }

        #scroll-style::-webkit-scrollbar
        {
            width: 2px;
            background-color: #fff;
        }

        #scroll-style::-webkit-scrollbar-thumb
        {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
            background-color: #e9e9e9;
            cursor: grab;
        }


    .gallery__leftPanel {
        position: absolute;
        width: 50%;
        height: 100vh;
        background: white;
        top:0;
        left:0;
        padding: 40px;
        padding-right: 80px;
        box-sizing: border-box;
        user-select: none;
        max-width: 900px;

        @media (max-width: $mobile) {
            width: 100% !important;
            position: relative !important;
            height: auto !important;
            padding: 25px;
            padding-bottom: 0;
        }
        
        h2 {
            font-size: 5.2rem;
            font-weight: $medium;
            margin: 55px 0;
            position: relative;
            left: -7px;
            line-height: 1em;

            @media (max-width: $mobile) {
                margin-top: 0;
                font-size: 14vw;
            }
        }

        p {
            margin: 0 0 25px 0;
        }

        strong {
            font-weight: $bold;
        }

        em {
            font-style: italic;
        }

        a {
            color: #000;
            text-decoration: none;
            font-weight: $medium;

            &:hover {
                opacity: 0.5;
            }
        }

        
    }

    .gallery__rightPanel {
        position: absolute;
        width: 50%;
        height: 100%;
        background: white;
        top:0;
        right:0;

        @media (max-width: $mobile) {
            width: 100% !important;
            position: relative !important;
            height: auto !important;
        }
    }

    .gallery__index {
        padding: 30px 20px;
        min-width: 300px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        transition: opacity .3s ease;
        background: white;

        @media (max-width: $mobile) {
            padding: 30px 10px;
            padding-top: 0;
        }

        .iframe-wrapper {           
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            height: 0;
            margin: 15px;
          }
          
          .iframe-wrapper iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

        .hideGalleryItem {
            opacity: 0;
            // height: 0;
            pointer-events: none;
            user-select: none;
            //visibility: hidden;
            width: 0 !important;
            border: 0 !important;
            transition: opacity .3s ease;

            @media (max-width: $mobile) {
                display: none;
            }
        }

        .preview-container {
            width: 100%;
            padding-top: 0%;
            background: white;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            margin: 0 26px;
            opacity: 0;
            transition: opacity 1s ease;
            cursor: pointer;

            &.square {
                padding-top: 100% !important;
            }

            &.in {
                padding-top: 50%;
                opacity: 1;
                transition: 1s ease;
            }
        }

        &.fadeOut {
            opacity: 0;
            transition: opacity .2s ease;
        }

        div {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .spacing {
            border: 20px solid #fff;
            width: 50%;
            box-sizing: border-box;

            @media (max-width: $mobile) {
                width: 50% !important;
                border: 0px solid #fff;
                border-bottom: 6px solid white;
            }
        }

        .image-wrapper {
            
            position: relative;

            button {
                border: none;
                display: inline;
                display: inline;
                background: none;
                height: 100%;
            }
            
            img {
                max-width: 100%;
                object-fit: cover;
                
                &:hover {
                    cursor: pointer;
                }
            }

        }

    }

    .gallery__item.video {

        .image-wrapper {
            height: 100%;
        }

        a {
            display: flex;
            height: 100%;

            img {
                object-fit: cover;
            }
        }
    }

    .gallery__index.layout4x {
        .spacing {
            width: 25%;
        }
    }

    .gallery__index.layout2x {
        .spacing {
            width: 50%;
        }
    }

    .gallery__index.books__index {
        padding-top: 150px;

        .spacing {
            width: 100% !important;
            border: 80px solid #fff;
            border-bottom: 20px solid #fff;
            border-top: 20px solid #fff;
        }
    }

    .btn-buy {
        border: 1px solid black;
        border-radius: 50px;
        text-align: center;
        margin: 55px 0;
        background: white;

        &:hover {
            background: black;
            a {
                color: white;
            }
        }
        a {
            color: black;
            text-decoration: none;
            font-weight: $bold;
            display: block;
            padding: 5px 30px;
        }
    }

}

.galleryFooterImage {
    @media (max-width: $mobile) {
        background-size: contain !important;
        height: auto !important;
        padding-top: 55%;
    }
}

.galleryFooterVideo {
    margin-top: 40px;
    height: unset !important;
    padding-top: 56.25% !important;

    @media (max-width: $mobile) {
        margin-top: 30px;
    }

    .iframe-wrapper {
        position: absolute !important;
        z-index: 1 !important;
        pointer-events: all !important;
        width: 100% !important;
        min-width: 100% !important;

        .iframe-overlay {
            height: 85%;
            opacity: .7;

            @media (max-width: $mobile) {
                height: 80%;
            }
        }
        
        iframe {
            width: 100% !important;
            min-width: 100% !important;
            pointer-events: all !important;
        }
    }
    
}

.galleryPage {
    @media (max-width: $mobile) {
        padding-top: 0 !important;
    }
}


.artwork-index-home {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 40px;
    background: white;

    @media (max-width: $mobile) {
        padding: 20px;
        justify-content: space-between;
    }

    >div {
        width: 23%;
        margin-right: 15.5%;
        margin-bottom: 100px;
        
        &:nth-child(3n)  {
            margin-right: 0;
        }

        @media (max-width: $mobile) {
            width: 48%;
            margin: 0 0 20px 0;
        }

    }

    a.home-gallery-index-item {
        display: block;
        position: relative;

        // &:after {
        //     content: 'View';
        //     position: absolute;
        //     top: 50%;
        //     left: 30px;
        //     color: white;
        //     font-weight: $medium;
        //     transform: translateY(-50%);
        //     mix-blend-mode: difference;
        //     opacity: 0;
        // }

        // &:hover {
        //     &::after {
        //         opacity: 1;
        //     }
        // }
    }

    img {
        max-width: 100%;
    }
}