@font-face {
    font-family: 'Swiss 721';
    src: url('static/fonts/Swiss721Bold.woff2') format('woff2'),
        url('static/fonts/Swiss721Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Swiss 721';
    src: url('static/fonts/Swiss721Roman.woff2') format('woff2'),
        url('static/fonts/Swiss721Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Swiss 721';
    src: url('static/fonts/Swiss721Medium.woff2') format('woff2'),
        url('static/fonts/Swiss721Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

h1, h2, h3, h4, h5, h6, label {
    letter-spacing: -0.04em;
    line-height: 1.1em;
}

p {
    font-size: 16px;
    line-height: 1.5em;
}