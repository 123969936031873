@import './variables';

.page__home {
    padding-top: 60px;
    background: white;

    @media (max-width: $mobile) {
        background: none;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent;
        z-index: 999;

        @media (max-width: $mobile) {
            display: none;
        }
    }

    .introSlide {
        height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
        border-left: 40px solid white;
        border-right: 40px solid white;
        background-position: top;
        pointer-events: none;
        position: relative;

        @media (max-width: $mobile) {
            border-left: 20px solid white;
            border-right: 20px solid white;
            //display: none;

            // &:nth-child(2)  {
            //     display: block;
            // }
        }

        a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
        }

        .iframe-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            pointer-events: none;
            overflow: hidden;

            @media screen and (max-width: $mobile){
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                pointer-events: none;
                overflow: hidden;
                margin: 0;
            }
            
          }
          
          .iframe-wrapper iframe {
            width: 100vw;
            height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
            min-height: 100vh;
            min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
            position: absolute;
            top: 50%;
            left: 50%;
            pointer-events: none;
            transform: translate(-50%, -50%);
            z-index: -1;
          }
    }

    .slide {
        height: auto;
        min-height: 100vh;
        position: relative;
        
        &.secondary {
            z-index: 999;
        }

        section {
            height: 100%;
            background-size: cover;
        }

        > div {
            object-fit: cover;
        }

        > div > img {
            opacity: 1;
            transition: opacity 1s;
            max-width: 100%;
        }
        
        > div > img.twic-done {
            opacity: 1;
            transition: opacity 1s;
        }

    

       
    }
}

.artwork-index-home {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 40px;
    background: white;

    @media (max-width: $mobile) {
        padding: 20px;
        justify-content: space-between;
    }

    >div {
        width: 23%;
        margin-right: 15.5%;
        margin-bottom: 100px;
        
        &:nth-child(3n)  {
            margin-right: 0;
        }

        @media (max-width: $mobile) {
            width: 48%;
            margin: 0 0 20px 0;
        }

    }

    a.home-gallery-index-item {
        display: block;
        position: relative;

        // &:after {
        //     content: 'View';
        //     position: absolute;
        //     top: 50%;
        //     left: 30px;
        //     color: white;
        //     font-weight: $medium;
        //     transform: translateY(-50%);
        //     mix-blend-mode: difference;
        //     opacity: 0;
        // }

        // &:hover {
        //     &::after {
        //         opacity: 1;
        //     }
        // }
    }

    img {
        max-width: 100%;
    }
}