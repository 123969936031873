.info {

    .gallery__leftPanel {
        padding-top: 90px;
    }

    .sideNav {
        a {
            font-size: 1.5rem;
            font-weight: $medium;
            line-height: 1.1em;
            color: black;
            text-decoration: none;

            &:hover {
                opacity: 0.1;
            }

            &.active {
                opacity: 0.1 !important;
            }
        }

        @media (max-width: $mobile) {
            display: none;
        }
    }

    .info-main {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        flex-direction: column;

        > div {
            width: 100%;
        }


        .profile-photo {
            img {
                max-width: 270px;

                @media (max-width: $mobile) {
                    max-width: 100%;
                }
            }
        }
    }

    .info__index {

        padding-top: 140px;
        padding-right: 40px;

        @media (max-width: $mobile) {
            padding: 20px;
            padding-top: 0;
        }

        p {
            margin: 0 0 25px 0;
        }

        strong {
            font-weight: $bold;
        }

        em {
            font-style: italic;
        }

        a {
            color: #000;
            text-decoration: none;
            font-weight: $medium;

            &:hover {
                opacity: 0.5;
            }
        }
    }
}