@import './style/variables.scss';
@import './style/reset.scss';
@import './style/typography.scss';
@import './style/navigation.scss';
@import './style/navigationMobile.scss';
@import './style/scroll.scss';
@import './style/home.scss';
@import './style/news.scss';
@import './style/gallery.scss';
@import './style/info.scss';
@import './style/books.scss';
@import './style/grid.scss';
@import './style/footer.scss';
@import './style/lightgallery.scss';
@import './style/exhibitions.scss';

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
  }



@media (min-width: $tablet) {
    :root {
        //font-size: calc(100vw / 1920 * 10);
        font-size: calc(1rem + ((1vw - 7.68px) * 0.6944));
        min-height: 0vw;
    }
}

@media (min-width: $desktop) {
    :root {
        font-size: 26.666px;
    }
}

html {
    position: fixed;
    height: 100%;
    overflow: hidden;
    background: white;

    @media (max-width: $mobile) {
        position: relative;
    }
}


body {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    margin: 0;
    padding: 0;
    font-family: 'Swiss 721', "Lucida Grande", Tahoma, Sans-Serif;
}

.has-scroll-scrolling {
    iframe {
        pointer-events: none;
    }
}

@media (max-width: $mobile) {
    html, body {
        height: 100%;
    }

    .site-wrapper {
        height: unset;
    }
}

.cursor {
    display: none;
    // position: absolute;
    // // background: white;
    // // width: 20px;
    // // height: 20px;
    // // border-radius: 50%;
    // z-index: 9999;
    // mix-blend-mode: difference;
    // pointer-events: none;

    // width: 0px;
    // height: 0px;
    // border-style: solid;
    // border-width: 0 10px 20px 10px;
    // border-color: transparent transparent #ffffff transparent;
    // transform: rotate(-40deg);
}

// html, body {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     overflow: hidden;
//     margin: 0;
//     padding: 0;
//     font-family: 'Swiss 721';
// }

.site-wrapper {
    transition: opacity .1s ease;
    &.is-leaving {
        pointer-events: none;
        opacity: 0;
    }
}

@media screen and (orientation:landscape)
    and (min-device-width: 319px) 
    and (max-device-width: $mobile) {
        .site-wrapper {
            display: none;
        }
}

.preloader {
    position: fixed;
    top: 0;
    bottom:0;
    left: 0;
    right: 100vw;
    background: #f6f6f6;
    z-index: 1090;
    transition: opacity .8s ease;
    pointer-events: none;
    user-select: none;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;

    img {
        width: 35px;
        height: 35px;
    }

    @media screen and (orientation:landscape)
    and (min-device-width: 319px) 
    and (max-device-width: $mobile) {
        display: none;
    }
}

.holder {
    height: 100vh;
    background: white;

    &.blue {
        background: blue;
    }

    &.yellow {
        background: yellow;
    }

    &.green {
        background: green;
    }
}

[data-load-container] {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

blockquote {
    display: block;
    margin-left: 30px;
}