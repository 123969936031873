.book-index-home {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 40px;
    background: white;

    .inner {
        max-width: 980px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; 
    }

    .inner>div {
        width: 40%;
        margin-right: 10%;
        margin-bottom: 100px;
        
        
        &:nth-child(2n)  {
            margin-right: 0;
        }

        @media (max-width: $mobile) {
            width: 100%;
            margin: 0 0 40px 0;
        }

    }

    a.home-gallery-index-item {
        display: block;
        position: relative;

        // &:after {
        //     content: attr(data-title);
        //     position: absolute;
        //     top: 50%;
        //     left: 30px;
        //     color: white;
        //     font-weight: $medium;
        //     transform: translateY(-50%);
        //     mix-blend-mode: difference;
        //     opacity: 0;
        // }

        // &:hover {
        //     &::after {
        //         opacity: 1;
        //     }
        // }
    }

    img {
        max-width: 100%;
    }
}

.page-books {
    @media (max-width: $mobile) {
        .gallery__leftPanel h2 {
            margin-top: 55px;
        }

        .gallery__index  {
            padding-top:0 !important;
        }

        .gallery .gallery__index.books__index .spacing {
            border: 15px solid #fff;
        }
    }
}