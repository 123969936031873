.page-exhibitions {
    @media (max-width: $mobile) {
        .gallery__leftPanel h2 {
            margin-top: 55px;
        }

        .gallery__index  {
            padding-top:0 !important;
        }

        .gallery .gallery__index.books__index .spacing {
            border: 15px solid #fff;
        }
    }
}