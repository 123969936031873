
.page__news, .news-article {

    .artwork-index-home {
        padding-top: 0;
    }

    .gallery .gallery__index.books__index {
        padding-top: 0;
    }

    .gallery-headline h1 {
        display: block;
        font-size: 10rem !important;
        margin-bottom: 20px !important;

        @media (max-width: $mobile) {
           font-size: 5.2rem !important;
        }
    }

    .gallery__leftPanel {
        padding-top: 20px !important; 

        h2 {
            margin-top: 0 !important;
        }
    }

    .video {
        width: 50%;
        position: relative;
    }
    
    .video > .video-height {
        padding-top: 60%;
        display: block;
    }

    .video-wrapp {
        position: relative
    }

    .videoPassthrough {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
        padding-top: 40%;
    }

    .embed-container {
        --video--width: 1920;
        --video--height: 1080;
      
        position: relative;
        padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
        overflow: hidden;
        max-width: 100%;
        margin: auto;
        background: white;
        margin: 0 40px;

        @media screen and (max-width: $mobile){
            margin: 0 20px;
            margin-top: 55px;
        }
      }
      
      .embed-container iframe,
      .embed-container object,
      .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }


    .film-description {
        margin: auto;
        margin-top: 40px;
        max-width: 600px;
        text-align: center;
        margin-bottom: 40px;

        @media (max-width: $mobile) {
            padding: 20px;
            padding-top:0;
            text-align: left;
        }

        h2 {
            font-size: 5.2rem;
            font-weight: $medium;
            margin: 55px 0;
            position: relative;
            left: -7px;
            line-height: 1em;
            
        }

        p {
            margin: 0 0 25px 0;
        }

        strong {
            font-weight: $bold;
        }

        em {
            font-style: italic;
        }

        a {
            color: #000;
            text-decoration: none;
            font-weight: $medium;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .filmContainer {
        width: 100%;
        padding-top: 56.25vw;
        position: relative;
        pointer-events: none;

        .iframe-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
            min-height: 100vh;
            min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        }

        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }

    .introSlide {
        height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
        border-left: 40px solid white;
        border-right: 40px solid white;
        background-position: top;

        @media (max-width: $mobile) {
            border-left: none !important;
            border-right: none !important;
        }

        a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
        }

        .iframe-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            pointer-events: none;
            overflow: hidden;
          }
          
          .iframe-wrapper iframe {
            width: 100vw;
            height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
            min-height: 100vh;
            min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
    }

    .filmItem {
        padding-top: 56.25%;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: -1px;
            object-fit: cover;
            height: 100%;
            transform: scale(1.1);
        }
    }

    .gallery-headline {
        min-height: 30vh;
        h1 {
            font-size: 6rem;
        }
    }

    .article-item {
        width: 33.33%;
        margin-right: 0%;

        @media (max-width: $mobile) {
            width: 50%;
        }

        label {
            font-size: 15px;
            font-weight: $medium;
            margin: 25px 0 .3em 0;
            display: block;
        }

        h2 {
            font-size: 20px;
            font-weight: $medium;
            margin: 0 0 .3em 0;
            display: none;
        }

        p {
            font-size: 15px;
            margin: 0 0 10px 0;
        }

        button {
            border: 1px solid #000;
            padding: 5px 20px;
            text-align: center;
            background: none;
            border-radius: 50px;
            font-weight: $medium;
            display: none;

            &:hover {
                background: #000;
                a { color: #fff; }
            }

            a {
                display: block;
                text-decoration: none;
                color: black;
                font-weight: $bold;
                font-size: 12px;
            }
        }
    }

    .gallery-headline h1 { 
        margin-bottom: 0;
    }
}
