.navigation-mobile {
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 1080;
    left: 0;
    right: 0;
    box-sizing: border-box;
    align-items: flex-start;
    padding: 15px 20px 15px 20px;
    background: white;
    height: 55px;

    @media (min-width: $mobile) {
        display: none;
    }

    @media screen and (orientation:landscape)
    and (min-device-width: 319px) 
    and (max-device-width: $mobile) {
        display: none;
    }


    &.hidden {
        .menu-items {
            opacity: 0;
            pointer-events: none;
        }
    }

    a {
        color: black;
        text-decoration: none;
    }

    h1 {
        margin: 0;
        padding: 0;
        font-size: 0.9em;
        font-weight: $bold;
        font-size: 18px;
        letter-spacing: 0;
        height: 35px;
        line-height: 25px;

        @media (max-width: $mobile) {
            width: 100%;
            font-size: 16px;
        }
    }

    .inner {
        width: 80%;
        display: flex;
        justify-content: flex-end;
    }

    .menu-items {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: opacity .4s ease;
            
        position: absolute;
        width: 200px;
        left: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 3px;

        opacity: 0;
        user-select: none;
        pointer-events: none; 

        &.mobileShow {
            opacity: 1 !important;
            pointer-events: all;
        }

        &.active {
            ul {
                opacity: 1;
                user-select: none;
                pointer-events: all;    

                li {
                    opacity: 1;
                    transition: opacity 1s ease;
                }
            }
        }

        button {
            font-weight: bold;
            font-size: 18px; 
            text-decoration: none;
            color: black;
            // cursor: pointer;
            transition: opacity .4s ease;
            border: none;
            background: none;

            @media (max-width: $mobile) {
                font-size: 16px;
            }

            @for $i from 1 through 20 {
                &:nth-child(#{$i}) {
                    z-index: $i;
                }
            }

            &:last-child {
                margin-right: 3%;
            }

            &.active {
                ul {
                    opacity: 1;
                    user-select: none;
                    pointer-events: all;    

                    li {
                        opacity: 1;
                        transition: opacity 1s ease;
                    }
                }
            }
            
            span:hover {
                opacity: 0.5;
                transition: opacity .4s ease;
            }
        }   

        ul {
            position: absolute;
            bottom: 40px;
            top: 0;
            padding-top: 55px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 55px;
            font-size: 5vh;
            letter-spacing: -0.04em;
            display: flex;
            pointer-events: none;

            @media (max-width: $mobile) {
                left: 20px;
                bottom: 20px;
                top: unset;
                position: fixed;
                height: auto !important;
                padding-bottom: 0;
                justify-content: flex-end;
            }
            
            &.secondary-sub {
                height: 32%;
                font-size: 5vh;
                opacity: 0;
            }
            
            li {
                text-align: left;
                font-weight: $medium;
                font-family: 'Swiss 721', "Lucida Grande", Tahoma, Sans-Serif;
                opacity: 0;
                position: relative;
                transition: opacity .2s ease;

                span {
                    background-size: 100%;
                    background-repeat: no-repeat;
                    position: absolute;
                    width: 12vh;
                    height: 12vh;
                    z-index: 999;
                    transform: translate(-110%,-20%);
                    opacity: 0;
                    transition: opacity .2s ease;
                }

                &:hover {
                    span {
                        opacity: 1;
                        transition: opacity .2s ease;
                    }
                }

                a {
                    &:hover {
                        opacity: .2;
                    }
                }
            }
        }

        &.hide {
            opacity: 0;
            pointer-events: none;
            transition: opacity .2s ease;
        }
    }

    .btn-close {
        margin: 0;
        padding: 0;
        font-size: 0.9em;
        font-weight: $bold;
        font-size: 16px;
        letter-spacing: 0;
        height: 35px;
        line-height: 33px;
        position: absolute;
        top: 10px;
        right: 15px;
        opacity: 0;
        pointer-events: none;
        cursor: pointer;
        z-index: 1060;
        transition: opacity .1s ease;
        border: none;
        background: none;
        color:black !important;

        &.active {
            opacity: 1;
            //transition: opacity .2s ease;
            pointer-events: all;
        }
    }

    .controls {
        display: flex;
        margin-right: 10px;

        @media (max-width: $mobile) {
            margin-right: 0;
        }

        button {
            display: inline-block;
            border-radius: 50%;
            border: 1px solid black;
            width: 25px;
            height: 25px;
            background: black;
            transition: opacity 0.3s ease-in-out;
            pointer-events: none;
            opacity: 0;
            transform: scale(1);
            transition:  0.3s ease-in-out;
            text-decoration: none;
            color: black;
            margin-right: 5px;
            padding:0;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            &.btn-menu-mobile {
                background: black;
                opacity: 1;
                pointer-events: all;

                &.mask {
                    background: white;
                    border-color: white;
                    pointer-events: none;

                    @media (max-width: $mobile) {
                        background: black;
                        border-color: black;
                        pointer-events: all;
                    }

                    &.hidden {
                        opacity: 0;
                        transition: .4s ease;
                        pointer-events: none;
                    }
                }
            }

            &.btn-galleryFull {
                background: white;
            }

            &:hover {
                transform: scale(1.1);
                transition:  0.3s ease-in-out;
            }
        }
    }

    .controls.active {
        button.btn-gallery2x, a.btn-gallery4x {
            overflow: hidden;

            img {
                transform: scale(1.1);
            }
        }

        button.btn-galleryFull, button.btn-gallery2x, button.btn-gallery4x {
            opacity: 1;
            pointer-events: all;

            @media (max-width: $mobile) {
                display: none;
            }
        }
    }

    .navigation__background {
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: white;
        opacity: 1;
        transition: opacity .4s ease;

        @media (max-width: $mobile) {
            &.mobileShow {
                opacity: 1 !important;
                pointer-events: all !important;
            }
        }

        &.closed {
            pointer-events: none;
            user-select: none;
            opacity: 0;
            transition: opacity .4s .4s ease;
        }
    }
}
