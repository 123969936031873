.grid {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    z-index: 9999;
    pointer-events: none;
    user-select: none;
    background: #9f9f9f;
    padding: 48px 40px 40px 40px;
    opacity: .4;
    display: none;

    .col {
        width: 6.25%;
        background: purple;
        margin-right: 35px;
        height: 100%;
        opacity: .4;

        &:last-child {
            margin-right: 0;
        }
    }
}