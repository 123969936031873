
@import 'variables';

footer {
    height: auto;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 40px;
    background: white;
    align-items: flex-start;

    @media (max-width: $mobile) {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        justify-content: space-between;
        padding: 20px;
    }


    .footer__left {
        @media (max-width: $mobile) {
            margin-bottom: 30px;
        }

        h3 {
            span {
                display: block;
                opacity: 0.2;
            }
        }
        
        label {
            display: block;
            line-height: 1.1rem;
        }
    }

    .footer__contact {
        margin-bottom: 30px;
    }

    section {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;

        @media (max-width: $mobile) {
            width: 100%;
        }

        a {
            color: black;
            text-decoration: none;
            transition: opacity .2s ease;

            &:hover {
                opacity: .2;
                transition: opacity .2s ease;
            }
        }

        &:first-child {
            width: 33.33%;

            @media (max-width: $mobile) {
                width: 100%;
            }
        }

        &:last-child {
            width: 33.33%;

            @media (max-width: $mobile) {
                width: 100%;
            }
        }

        h2 {
            font-size: 3rem;
            font-weight: $medium;
        }

        h3 {
            font-size: 1.5rem;
            font-weight: $medium;

            @media (max-width: $mobile) {
                font-size: 1.3rem;
            }
        }

        label {
            font-size: 0.6rem;
            font-weight: $medium;

            @media (max-width: $mobile) {
                line-height: 3em;
            }
        }
    }
}